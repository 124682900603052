import React from "react";

//styles
import "./Header.css";

//import components
import MusicToggle from "../../../components/MusicToggle";
import FullscreenToggle from "../../../components/FullscreenToggle";
import TextRotator from "../../../components/TextRotator";
//
const Header = React.memo(() => {
  //console.log("%c header component running...", "background: #222; color: red");
  return (
    <header className="header">
      <section className="section header-panel">
        <div className="viewport">
          <MusicToggle />
          <div className="">
            <FullscreenToggle />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="viewport">
          <div className="v-spacer-40 mob"></div>
          <TextRotator className="text-rotator" />
        </div>
      </section>
    </header>
  );
});

export default Header;
