import React, { useRef, useEffect, useContext } from "react";
//import PropTypes from 'prop-types'

//styles
import "./VideoBackground.css";

//import video context
import { VideoContext } from "../../contexts/VideoContext";
import usePreviousState from "../../hooks/usePreviousState";
//import utils
//import named classes from modules e.g import { cube, foo, graph } from './my-module.js';
//import { reveal } from "../../utils/reveal.js";

//
import config from "../../config/config";

//rfce
const VideoBackground = React.memo((props) => {
  //video Elm reference
  const videoWrapper = useRef(null);
  const videoElm = useRef(null);
  //const prevClassRef = useRef();

  //Context Example
  //destructuring to pull out the needed functions or states variable from the Global context
  const { videoSrc, posterSrc, screenType, docTouched } =
    useContext(VideoContext);

  //
  const prevScreenType = usePreviousState(screenType);

  //
  useEffect(() => {
    if (docTouched === true) {
      //videoElm.current.play();
      //listen for CANPLAY event
      videoElm.current.addEventListener("canplaythrough", function () {
        videoElm.current.play();
      });
    }
    //
    videoElm.current.addEventListener("canplaythrough", function () {
      videoElm.current.play();
    });
  }, [docTouched]);

  //
  useEffect(() => {
    //console.log("video changed...");

    let introTimer;
    let outroTimer;

    //videoElm.current.classList.remove("transition-medium");
    videoElm.current.style.opacity = "0";
    outroTimer = setTimeout(() => {
      //console.log(prevClass);
      //console.log(prevScreenType);
      videoWrapper.current.classList.remove(`screenType-${prevScreenType}`);
      videoWrapper.current.classList.add(`screenType-${screenType}`);
      videoElm.current.src = videoSrc;
      videoElm.current.controls = false;
      videoElm.current.volume = props.volume || 1; //|| props default
      //videoElm.current.autoplay = props.autoplay || true;
      videoElm.current.poster = posterSrc; //|| defaultPoster;
      videoElm.current.loop = props.loop || true;
      videoElm.current.mute = props.mute || true;
      videoElm.current.load();
    }, 1000);

    videoElm.current.onloadeddata = function () {
      introTimer = setTimeout(() => {
        videoElm.current.classList.add("transition-medium");
        videoElm.current.style.opacity = "1";
      }, 300);
    };

    //
    //prevClassRef.current = screenType;

    //
    return () => {
      clearTimeout(introTimer);
      clearTimeout(outroTimer);
    };
    //console.log("Video component runs");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoSrc, posterSrc]);

  //
  //const prevClass = prevClassRef.current;

  return (
    <div className="video-wrapper" ref={videoWrapper}>
      <div className="video-player noselect">
        <video
          className="video accelerated"
          ref={videoElm}
          muted
          //autoPlay
          preload="auto"
          playsInline
        >
          {/* <source src={videoSrc} type="video/mp4" /> */}
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
});

// Specifies the default values for props:
VideoBackground.defaultProps = {
  volume: 1,
  autoplay: true,
  loop: true,
  mute: true,
  poster: config.sectionsBg[0].poster,
};

export default VideoBackground;
