import { useState, useEffect } from "react";

//useBrowserSize hook
export default function useWindowSize() {
  function getSize() {
    //
    let width;
    let height;

    //
    if (window.innerWidth) {
      width = window.innerWidth;
    } else if (
      document.documentElement &&
      document.documentElement.clientWidth !== 0
    ) {
      width = document.documentElement.clientWidth;
    } else if (document.body) {
      width = document.body.clientWidth;
    }

    //
    if (window.innerHeight) {
      height = window.innerHeight;
    } else if (
      document.documentElement &&
      document.documentElement.clientHeight !== 0
    ) {
      height = document.documentElement.clientHeight;
    } else if (document.body) {
      height = document.body.clientHeight;
    }

    return {
      width: width,
      height: height,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

//get width hook
export const getBrowserWidth = () => {
  if (window.innerWidth) {
    return window.innerWidth;
  } else if (
    document.documentElement &&
    document.documentElement.clientWidth !== 0
  ) {
    return document.documentElement.clientWidth;
  } else if (document.body) {
    return document.body.clientWidth;
  }
  return 0;
};

//get height hook
export const getBrowserHeight = () => {
  if (window.innerHeight) {
    return window.innerHeight;
  } else if (
    document.documentElement &&
    document.documentElement.clientHeight !== 0
  ) {
    return document.documentElement.clientHeight;
  } else if (document.body) {
    return document.body.clientHeight;
  }
  return 0;
};

//detect mobile hook
export const detectMobileDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
