import React, { createContext, useState, useEffect } from "react";
//custom hooks
import { detectMobileDevice } from "../hooks/useWindowSize";
import useNativeScroll from "../hooks/useNativeScroll";

//export ScrollRigContext
export const ScrollRigContext = createContext();
//
const ScrollRigContextProvider = (props) => {
  //create a global state
  const [scrollOffset, setScrollOffset] = useState(null);
  const [scrollSkew, setScrollSkew] = useState(false);

  //hook 1
  let isMobile = detectMobileDevice();
  //hook 2
  let nativeScrollOffset = useNativeScroll();

  //
  useEffect(() => {
    if (isMobile) setScrollOffset(nativeScrollOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativeScrollOffset]);

  //
  return (
    //pass the global state and the toggle function
    <ScrollRigContext.Provider
      value={{ setScrollOffset, setScrollSkew, scrollOffset, scrollSkew }}
    >
      {props.children}
    </ScrollRigContext.Provider>
  );
};

export default ScrollRigContextProvider;
