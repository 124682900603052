import React, { useState, useContext, useEffect } from "react";
import { useTrail, animated } from "react-spring";
import "./WelcomeScreen.css";
//context import
import { MusicContext } from "../../../contexts/MusicContext";
import { VideoContext } from "../../../contexts/VideoContext";

//mui
//import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import MusicOffIcon from "@material-ui/icons/MusicOff";
import { makeStyles } from "@material-ui/core";

const fast = { tension: 1200, friction: 40 };
const slow = { mass: 10, tension: 200, friction: 50 };
const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;
//const blur = (blurEffect) => `blur(${blurEffect}px`;

//material ui styles
const useStyles = makeStyles(
  //
  (theme) => ({
    musicOnBtn: {
      color: "var(--secondary-color)",
      borderColor: "rgba(64, 194, 210, 0.5)",
      "&:hover": {
        borderColor: "rgba(64, 194, 210, 1)",
      },
      //background: "var(--secondary-color)",
    },
    musicOffBtn: {
      //background: "var(--secondary-color)",
    },
  })
);

//rfce
const WelcomeScreen = React.memo((props) => {
  //use material custom styles
  const classes = useStyles();

  //
  const [trail, set] = useTrail(4, () => ({
    xy: [0, 0],
    blurEffect: 0,
    config: (i) => (i === 0 ? fast : slow),
  }));

  //Context Example
  //destructuring to pull out the needed functions or states variable from the Global context
  const { changeDocState } = useContext(VideoContext);

  //
  //const classes = useStyles();

  //define state
  const [welcomeScreen, setWelcomeScreen] = useState(true);

  //Context Example
  //destructuring to pull out the needed function or state variable from the context
  const { toggleMusic } = useContext(MusicContext);

  //
  useEffect(() => {
    let timer;
    if (welcomeScreen === false) {
      timer = setTimeout(() => {
        document.body.classList.remove("scroll-disabled");
      }, 1500);
    }
    //
    return () => clearTimeout(timer);
  }, [welcomeScreen]);

  //
  const hideWelcomeScreen = () => {
    //console.log("hide welcome screen");
    setWelcomeScreen(false);
  };

  //
  return (
    //A simple possible syntax to toggle classes will be by predefining the state value as the classname:
    //<main className={`welcome-screen ${wsStatus}`}>
    //or
    <main
      className={
        "welcome-screen " +
        (welcomeScreen ? "show" : "hide") +
        " transition-medium "
      }
    >
      <div
        className="hooks-main"
        onMouseMove={(e) =>
          set({
            xy: [e.clientX, e.clientY],
            /*blurEffect: 7,
              onRest: () => {
              set({ blurEffect: 0 });
            }, */
          })
        }
      >
        {trail.map((props, index) => (
          <animated.div
            key={index}
            style={{
              transform: props.xy.interpolate(trans),
              //filter: props.blurEffect.interpolate(blur),
            }}
          >
            <div className="b-bg"></div>
          </animated.div>
        ))}
      </div>
      {/*  */}

      <section className="section">
        <div className="viewport">
          <div className="welcome-box">
            <h1 className="name hidden transition-medium accelerated">
              'eliehokayem'
            </h1>
            <h2 className="headline-ws hidden transition-medium accelerated">
              eliehokayem.com
            </h2>
            <hr className="devider hidden transition-medium accelerated" />
            <div className="v-spacer-20"></div>
            <h4 className="note hidden transition-medium accelerated">
              Choose Music Option & Procceed
            </h4>
            <div className="welcome-btns hidden transition-medium accelerated">
              <Button
                classes={{
                  root: classes.musicOnBtn, // class name, e.g.
                  //`classes-nesting-root-x`
                  //label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  toggleMusic();
                  hideWelcomeScreen();
                  changeDocState(true);
                }}
                startIcon={<MusicNoteIcon />}
              >
                music on
              </Button>
              <Button
                classes={{
                  root: classes.musicOffBtn, // class name, e.g.
                  //`classes-nesting-root-x`
                  //label: classes.label, // class name, e.g. `classes-nesting-label-x`
                }}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  hideWelcomeScreen();
                  changeDocState(true);
                }}
                startIcon={<MusicOffIcon />}
              >
                music off
              </Button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
});

export default WelcomeScreen;
