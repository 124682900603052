import React, { useEffect, useContext, useRef } from "react";
//import styles
import "./MusicPlayer.css";
//import utils
import EaseVolume from "../../utils/EaseVolume";
//context import
import { MusicContext } from "../../contexts/MusicContext";
//
import config from "../../config/config.js";

//rfce
const MusicPlayer = (props) => {
  //Context Example / global states
  //destructuring to pull out the needed function or state variable from the context
  const { musicPlaying, volumeLevel } = useContext(MusicContext);

  //local states

  //define Elm references
  const audioElm = useRef(null);

  //renders on musicPlaying state change, Each effect “belongs” to a particular render.
  useEffect(() => {
    //console.log("Audio component runs with music toggle");
    // some side effect code
    toglleMusic();
    //event handlers goes here in case available;
    //...
    //cleanup below
    //return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [musicPlaying]);

  //renders on volumeLevel state change
  useEffect(() => {
    //console.log("Audio component runs with volume change");
    // some side effect code
    changeVolume();
    //cleanup below
    //return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeLevel]);

  //toggle music on/off
  function toglleMusic() {
    //get audio element
    const audioElement = audioElm.current;

    //
    switch (musicPlaying) {
      case true:
        //audioElm.current.autoplay = props.autoplay || true;
        audioElement.loop = true;
        audioElement.load();
        audioElement.play();
        audioElement.volume = 0;
        EaseVolume(audioElement, volumeLevel.toFixed(2));

        break;
      case false:
        audioElement.pause();
        EaseVolume(audioElement, 0);

        break;
      default:
        audioElement.pause();
        EaseVolume(audioElement, 0);
    }
  }

  //update music volume
  function changeVolume() {
    //console.log(volumeLevel.toFixed(2));
    audioElm.current.volume = volumeLevel.toFixed(2);
  }

  //
  return (
    <React.Fragment>
      <audio className="audio" ref={audioElm}>
        <source src={config.music} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </React.Fragment>
  );
};

export default MusicPlayer;
