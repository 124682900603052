import React from "react";

//
import "./Footer.css";

//
import Copyright from "../../../components/Copyright";
import MusicToggle from "../../../components/MusicToggle";
import SocialMedialist from "../../../components/SocialMediaList";
/* import BtnLetters from "../../../components/BtnLetters/BtnLetters"; */

//mui
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

//rfce
const Footer = React.memo(() => {
  return (
    <footer className="footer">
      <div className="frosty-glass"></div>
      <section className="section footer-content">
        <div className="viewport">
          <SocialMedialist fonts="large" />
          <div className="v-spacer-20"></div>
          <h2>Let's Work & Join The Journey Together</h2>
          <br></br>
          <ul className="links-list">
            <li>
              <a className="transition-fast" href="tel:+96171817742">
                <PhoneIcon className="icon transition-fast" />
              </a>
              {/* <BtnLetters
                className="semi-bold"
                text="+961 71 81 77 42"
                url="tel:+96171817742"
                target="_self"
              /> */}
            </li>
            <li>
              <a
                className="transition-fast"
                href="mailto:hokayemelias@gmail.com"
              >
                <EmailIcon className="icon transition-fast" />
              </a>
              {/* <BtnLetters
                className="semi-bold"
                text="hokayemelias@gmail.com"
                url="mailto:hokayemelias@gmail.com"
                target="_self"
              /> */}
            </li>
            <li>
              <a
                className="transition-fast"
                href="https://api.whatsapp.com/send?phone=+96171817742"
              >
                <WhatsAppIcon className="icon transition-fast" />
              </a>
              {/* <BtnLetters
                className="semi-bold"
                text="hokayemelias@gmail.com"
                url="mailto:hokayemelias@gmail.com"
                target="_self"
              /> */}
            </li>
          </ul>
          <div className="v-spacer-20"></div>
          <MusicToggle />
          <div className="v-spacer-20"></div>
          <Copyright />
        </div>
      </section>
    </footer>
  );
});

export default Footer;
