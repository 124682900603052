import React from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";

const Navigation = React.memo((props) => {
  return (
    <nav className="nav">
      <ul className="nav-list">
        <li>
          <NavLink
            className="transition-fast"
            exact
            to="/"
            onClick={() => {
              //console.log("heading to profile page");
            }}
          >
            PROFILE
          </NavLink>
        </li>
        <li>
          <NavLink
            className="transition-fast"
            to="/skills"
            onClick={() => {
              //console.log("heading to skills page");
            }}
          >
            SKILLS
          </NavLink>
        </li>
        <li>
          <NavLink
            className="transition-fast"
            to="/works"
            onClick={() => {
              //console.log("heading to works page");
            }}
          >
            WORKS
          </NavLink>
        </li>
      </ul>
    </nav>
  );
});

export default Navigation;
