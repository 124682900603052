import React from "react";
//styles
import "./SocialMediaList.css";
//import fontawsome 5
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import individual fonts
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

//use font awsome library
/* import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab, fas); */

//fc
const SocialMediaList = (props) => {
  return (
    <ul className={"social-media transition-medium accelerated " + props.fonts}>
      <li>
        <a
          href="https://www.facebook.com/hokayemElie"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="transition-fast" icon={faFacebookF} />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/eliehokayem"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="transition-fast" icon={faInstagram} />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/hokayem_elie"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="transition-fast" icon={faTwitter} />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/in/elie-hokayem-61443566/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className="transition-fast" icon={faLinkedinIn} />
        </a>
      </li>
    </ul>
  );
};

export default SocialMediaList;
