import React, { useContext } from "react";

//mui
/* import Fab from "@material-ui/core/Fab"; */
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Zoom from "@material-ui/core/Zoom";
/* import useMediaQuery from "@material-ui/core/useMediaQuery"; */

//import context
import { FullscreenContext } from "../../contexts/FullscreenContext";

//styles
import "./FullscreenToggle.css";

//mui styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    width: "28px",
    padding: "0px",
  },
  white: {
    color: "white",
  },
  icon: {
    fontSize: "18px",
    "@media (max-width: 768px)": {
      fontSize: "27px",
    },
  },
}));

const defaultTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "11px",
        color: "rgb(3, 169, 244)",
        backgroundColor: "rgba(0,0,0,0.8)",
      },
    },
  },
});

//
const FullscreenToggle = (props) => {
  //console.log("%c fullscreen component running... ", "color: #bada55");

  //destructure Fullscreen context and pull the required states and functions and vars...
  const { toggleFullScreen, fullscreen } = useContext(FullscreenContext);

  //mui classes
  const classes = useStyles();
  /* const matches = useMediaQuery("(max-width:768px)"); */

  //render
  return (
    <MuiThemeProvider theme={defaultTheme}>
      {/*  */}
      <IconButton
        className={`${classes.Fab} ${classes.white} fs-toggle`}
        onClick={toggleFullScreen}
      >
        <Tooltip
          className="tooltip"
          title={fullscreen ? "Exit Fullscreen Mode" : "Enter Fullscreen Mode "}
          TransitionComponent={Zoom}
        >
          {fullscreen ? (
            <FullscreenIcon className={classes.icon} />
          ) : (
            <FullscreenExitIcon className={classes.icon} />
          )}
        </Tooltip>
      </IconButton>

      {/* <button className="fs-toggle" onClick={toggleFullScreen}>
        {fullscreen ? (
          <FontAwesomeIcon icon={faCompress} />
        ) : (
          <FontAwesomeIcon icon={faExpand} />
        )}
      </button> */}
    </MuiThemeProvider>
  );
};

export default FullscreenToggle;
