//
import React, { useContext } from "react";

//import music context
import { MusicContext } from "../../contexts/MusicContext";

//styles
import "./MusicToggle.css";

//mui
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import Zoom from "@material-ui/core/Zoom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

//mui styles
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  iconButton: {
    width: "28px",
    padding: "0px",
    "@media (max-width: 768px)": {
      width: "40px",
    },
  },
  white: {
    color: "white",
  },
  icon: { fontSize: "18px" },
  iconMobile: { fontSize: "18px" },
}));

const defaultTheme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "11px",
        color: "#ffc63b",
        backgroundColor: "rgba(0,0,0,0.8)",
      },
    },
  },
});

//
const MusicToggle = (props) => {
  //Context Example
  //destructuring to pull out the needed functions or states variable from the Global context
  const {
    musicPlaying,
    toggleMusic,
    changeVolume,
    volumeLevel,
    maxVolume,
    minVolume,
  } = useContext(MusicContext);

  //console.log("MusicToggle component runing " + musicPlaying);

  //mui classes
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:768px)");

  //
  return (
    <MuiThemeProvider theme={defaultTheme}>
      <div className="music-toggle-container">
        {/*  */}
        <IconButton
          className={`${classes.iconButton} ${classes.white}`}
          onClick={toggleMusic}
        >
          <Tooltip
            className="tooltip"
            title={musicPlaying ? "Turn Music Off" : "Turn Music On"}
            TransitionComponent={Zoom}
          >
            {musicPlaying ? (
              <VolumeUpIcon
                className={matches ? classes.iconMobile : classes.icon}
              />
            ) : (
              <VolumeOffIcon
                className={matches ? classes.iconMobile : classes.icon}
              />
            )}
          </Tooltip>
        </IconButton>
        {/*  */}
        <IconButton
          className={`${classes.iconButton} ${classes.white} volume-btn`}
          disabled={volumeLevel.toFixed(2) <= minVolume ? true : false}
          onClick={() => {
            //context
            changeVolume("down");
          }}
        >
          <Tooltip
            title={`Volume Down : ${volumeLevel.toFixed(1)}`}
            TransitionComponent={Zoom}
          >
            <RemoveCircleIcon
              className={matches ? classes.iconMobile : classes.icon}
            />
          </Tooltip>
        </IconButton>
        {/*  */}
        <IconButton
          className={`${classes.iconButton} ${classes.white} ${classes.disabled} volume-btn`}
          disabled={volumeLevel.toFixed(2) >= maxVolume ? true : false}
          onClick={() => {
            //context
            changeVolume("up");
          }}
        >
          <Tooltip
            title={`Volume Up : ${volumeLevel.toFixed(1)}`}
            TransitionComponent={Zoom}
          >
            <AddCircleIcon
              className={matches ? classes.iconMobile : classes.icon}
            />
          </Tooltip>
        </IconButton>

        {/*  <button
        className={
          "volume-up-btn " + (volumeLevel.toFixed(2) === maxVolume ? "disabled" : "")
        }
        onClick={() => {
          //context
          changeVolume("up");
        }}
      >
        <FontAwesomeIcon icon={faPlusCircle} />
      </button> */}
      </div>
    </MuiThemeProvider>
  );
};

export default MusicToggle;
