import React from "react";
import "./Copyright.css";

// Copyrights
let date = new Date();
let year = date.getFullYear();

//
function Copyright() {
  return (
    <p className="copyright-js">©Elie Hokayem {year}, All Rights Reserved.</p>
  );
}

export default Copyright;
