import React, {
  useContext,
  useLayoutEffect,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import { animated as a, useSpring, interpolate } from "react-spring";
import ResizeObserver from "resize-observer-polyfill";

//custom hooks
import { detectMobileDevice } from "../../hooks/useWindowSize";
//import VideoContext function from global video context
import { ScrollRigContext } from "../../contexts/ScrollRigContext";

//styles
import "./ScrollRig.css";

//rfce
function ScrollRig({ children, target, scrollIntertia }) {
  //destructuring ScrollRigContext
  const { setScrollOffset, scrollSkew } = useContext(ScrollRigContext);

  //refs
  const viewportRef = useRef(null);

  //states
  const [currentSize, setCurrentSize] = useState({
    width: target.innerWidth,
    height: target.innerHeight,
  });

  //device hook
  let isMobile = detectMobileDevice();

  //trans
  const trans = (x, y, skewX, skewY) =>
    `translate3d(${x}px,${y}px,0) skewX(${skewX}deg) skewY(${skewY}deg)`;
  /* const effect = (filter, filter2) =>
    `contrast(${filter}%) brightness(${filter2}%)`; */

  //react spring
  const [props, set] = useSpring(() => ({
    //from: { scale: 0 },
    x: 0,
    y: 0,
    xy: [0, 0],
    skewX: 0,
    skewY: 0,
    /* filter: 0,
    filter2: 0, */
    config: {
      mass: 1,
      tension: 170,
      friction: scrollIntertia,
      precision: 0.00001,
      velocity: 0,
      clamp: true,
    },
  }));

  //
  const getCurrentSize = useCallback((entries) => {
    for (let entry of entries) {
      const crx = entry.contentRect;
      setCurrentSize({ width: crx.width, height: crx.height });
    }
  }, []);

  useLayoutEffect(() => {
    //
    const viewport = viewportRef.current;
    if (!viewport) return;

    //ro
    let ro = new ResizeObserver((entries) => getCurrentSize(entries));
    ro.observe(viewport);

    //cleanup
    return () => {
      if (!ro) return;
      ro.disconnect();
    };
  }, [getCurrentSize]);

  useEffect(() => {
    //
    if (isMobile) return;

    //skew config
    const skewAmount = 0.25;
    let currentPosX = target.pageXOffset;
    let currentPosY = target.pageYOffset;

    //
    const handleScroll = () => {
      //set({ xy: [-target.pageXOffset, -target.pageYOffset] });
      /*  set({
        filter: 1000,
        filter2: 850,
        config: {
          mass: 1.5,
          tension: 300,
          friction: 26,
        },
        onRest: () => {
          set({ filter: 100, filter2: 100 });
        },
      }); */

      set({
        //x: -target.pageXOffset,
        //y: -target.pageYOffset,
        x: -target.scrollX,
        y: -target.scrollY,
        xy: [target.scrollX, target.scrollY],
        //
        onFrame: (value) => {
          //
          if (!scrollSkew) return;
          //skew
          const newPosX = target.pageXOffset;
          const newPosY = target.pageYOffset;
          const differenceX = newPosX - currentPosX;
          const differenceY = newPosY - currentPosY;
          const skewXspeed = differenceX * skewAmount;
          const skewYspeed = differenceY * skewAmount;
          //animate
          set({
            skewX: skewXspeed,
            skewY: skewYspeed,
          });
          //reset
          currentPosX = newPosX;
          currentPosY = newPosY;

          //post scroll values
          setScrollOffset({
            x: Number(value.xy[0].toFixed(0)),
            y: Number(value.xy[1].toFixed(0)),
          });
        },
      });
    };
    target.addEventListener("scroll", handleScroll, false);
    //cleanup
    return () => target.removeEventListener("scroll", handleScroll);
  }, [set, isMobile, target, setScrollOffset, scrollSkew]);

  //render condition
  let renderValue;
  if (isMobile) {
    renderValue = <>{children}</>;
  } else {
    renderValue = (
      <>
        <a.div
          style={{
            transform: interpolate(
              [props.x, props.y, props.skewX, props.skewY],
              trans
            ),
            //filter: interpolate([props.filter, props.filter2], effect),
            //transform: props.xy.interpolate(trans),
          }}
          ref={viewportRef}
          className="scroll-container gpu-acceleration"
        >
          {children}
        </a.div>
        <div style={{ height: currentSize.height }} />
      </>
    );
  }

  //render
  return renderValue;
}

export default ScrollRig;
