//development settings

//skills
import logo1 from "../media/skills/01.png";
import logo2 from "../media/skills/02.png";
import logo3 from "../media/skills/03.png";
import logo4 from "../media/skills/04.png";
import logo5 from "../media/skills/05.png";
import logo6 from "../media/skills/06.png";
import logo7 from "../media/skills/07.png";
import logo8 from "../media/skills/08.png";
import logo9 from "../media/skills/09.png";
import logo10 from "../media/skills/10.png";

//linked in
import card1 from "../media/skills/cards/js-card.jpg";
import card2 from "../media/skills/cards/node-card.jpg";
import card3 from "../media/skills/cards/mongo-card.jpg";
import card4 from "../media/skills/cards/html-card.jpg";
import card5 from "../media/skills/cards/css-card.jpg";
import card6 from "../media/skills/cards/json-card.jpg";

//music
import defaultMusic from "../media/audio/Extreme-Ways.mp3";

//bg video media
import video1 from "../media/video/space.mp4";
import video1Poster from "../media/video/space-poster.jpg";
import video2 from "../media/video/newyork.mp4";
import video2Poster from "../media/video/newyork-poster.jpg";
import video3 from "../media/video/catalina.mp4";
import video3Poster from "../media/video/catalina-poster.jpg";

//
const config = {
  //
  music: defaultMusic,
  //sections bg
  sectionsBg: [
    {
      poster: video1Poster,
      src: video1,
    },
    {
      poster: video2Poster,
      src: video2,
    },
    {
      poster: video3Poster,
      src: video3,
    },
  ],
  //skills
  skills: [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
  ],
  //skills
  linkedin: [
    {
      img: card6,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6742861215531364352-erfv",
    },
    {
      img: card5,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6742878731834060800-qjVg",
    },
    {
      img: card4,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6742859401796878336-T_m2",
    },
    {
      img: card3,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6751757333765926912-tyFd",
    },
    {
      img: card2,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6742863988884856832-Bs8B",
    },
    {
      img: card1,
      url:
        "https://www.linkedin.com/posts/eliehokayem_linkedinskillassessment-activity-6742856885168328704-0K92",
    },
  ],

  //members
  membersCarousel: {
    visibleSlides: 4,
    infinite: true,
    slides: [
      logo1,
      logo2,
      logo3,
      logo4,
      logo5,
      logo6,
      logo7,
      logo8,
      logo9,
      logo10,
    ],
  },

  //
  rotatingText: [
    { text: "WEB APPS DEVELOPER", color: "--primary-color" },
    { text: "WEB DESIGNER", color: "--primary-color" },
    { text: "UI/UX PRODUCT DESIGNER", color: "--secondary-color" },
  ],
};

export default config;
