import React, { useState, useEffect } from "react";

//styles
import "./TextRotator.css";
import config from "../../config/config.js";

//rfce
function TextRotator() {
  //
  const [counter, setCounter] = useState(0);

  //
  useEffect(() => {
    //
    const totalData = config.rotatingText.length;

    //
    let timer = setInterval(() => {
      setCounter((prevState) => {
        return prevState < totalData - 1 ? prevState + 1 : 0;
      });
    }, 1500);
    //
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  //
  //console.log(counter);

  //
  return (
    <div className="text-rotator-box">
      <ul
        className="text-rotator-list transition-fast gpu-acceleration"
        style={{ transform: `translateY(-${counter * 40}px)` }}
      >
        {config.rotatingText.map((item, index) => {
          return (
            <li
              className="text-rotator-list-item gpu-acceleration"
              key={index}
              style={{ color: `var(${item.color})` }}
            >
              {item.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default TextRotator;
