import React from "react";
import "./Frame.css";

const Frame = () => {
  return (
    <div className="viewport">
      <div className="frame-top"></div>
      <div className="frame-left"></div>
      <div className="frame-right"></div>
      <div className="frame-bottom"></div>
    </div>
  );
};

export default Frame;
