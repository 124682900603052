import React, { createContext, useState } from "react";

//config
import config from "../config/config.js";

//
export const VideoContext = createContext();

//
const VideoContextProvider = (props) => {
  //states
  const [posterSrc, setPosterSrc] = useState(config.sectionsBg[1].poster);
  const [videoSrc, setVideoSrc] = useState(config.sectionsBg[1].src);
  const [screenType, setScreenType] = useState(1);
  const [docTouched, setDocTouched] = useState(false);

  //
  const switchVideoBG = (value) => {
    switch (value) {
      case "profile":
        setPosterSrc(config.sectionsBg[1].poster);
        setVideoSrc(config.sectionsBg[1].src);
        setScreenType(1);
        break;
      case "skills":
        setPosterSrc(config.sectionsBg[0].poster);
        setVideoSrc(config.sectionsBg[0].src);
        setScreenType(2);
        break;
      case "works":
        setPosterSrc(config.sectionsBg[2].poster);
        setVideoSrc(config.sectionsBg[2].src);
        setScreenType(3);
        break;
      case "error":
        setPosterSrc(config.sectionsBg[0].poster);
        setVideoSrc(config.sectionsBg[0].src);
        setScreenType(3);
        break;
      default:
        setPosterSrc("");
        setVideoSrc("");
    }
  };

  //set document touched to true for autoplay
  const changeDocState = (value) => {
    setDocTouched(value);
  };

  //render
  return (
    <VideoContext.Provider
      value={{
        videoSrc,
        posterSrc,
        screenType,
        docTouched,
        switchVideoBG,
        changeDocState,
      }}
    >
      {props.children}
    </VideoContext.Provider>
  );
};

//export { VideoContext };//same as export const... above
export default VideoContextProvider;
