import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//import Global contexts
import MusicContextProvider from "./contexts/MusicContext";
import VideoContextProvider from "./contexts/VideoContext";
import FullscreenContextProvider from "./contexts/FullscreenContext";
import ScrollRigContextProvider from "./contexts/ScrollRigContext";

//styles
import "./styles/App.css";

//components
import Preloader from "./components/Preloader";
import ScrollRig from "./components/ScrollRig";
import WelcomeScreen from "./views/layout/WelcomeScreen";
import VideoBackground from "./components/VideoBackground";
import Header from "./views/layout/Header";
import Navigation from "./views/layout/Navigation";
import Footer from "./views/layout/Footer";
import Frame from "./components/Frame";
import MusicPlayer from "./components/MusicPlayer";

//lazy Components / Routes - views import
const Profile = lazy(() => import("./views/Profile"));
const Skills = lazy(() => import("./views/Skills"));
/* const Blog = lazy(() => import("./views/Blog")); */
const Works = lazy(() => import("./views/Works"));
/* const WorkDetails = lazy(() => import("./views/WorkDetails"));
const Login = lazy(() => import("./views/auth/Login"));
const Register = lazy(() => import("./views/auth/Register")); */
const ErrorPage = lazy(() => {
  return import("./views/ErrorPage");
});

//require imagesLoaded node module
//const imagesLoaded = require("imagesloaded");

//app functional component
const App = () => {
  //app content
  return (
    <MusicContextProvider>
      <FullscreenContextProvider>
        <VideoContextProvider>
          <ScrollRigContextProvider>
            <Suspense fallback={<Preloader status="active" />}>
              <div className="App">
                <VideoBackground
                  volume={0}
                  autoplay={true}
                  loop={true}
                  mute={true}
                />
                <ScrollRig target={window} scrollIntertia={26}>
                  <main className="main">
                    <Router>
                      <Header />
                      <Navigation />
                      <main className="main-body">
                        <Switch>
                          <Route exact path="/" component={Profile} />
                          <Route path="/skills" component={Skills} />
                          <Route path="/works" component={Works} />
                          <Route path="*" component={ErrorPage} />
                        </Switch>
                      </main>
                      <Footer />
                    </Router>
                  </main>
                </ScrollRig>
                <MusicPlayer />
                <WelcomeScreen />
                <Frame />
              </div>
            </Suspense>
          </ScrollRigContextProvider>
        </VideoContextProvider>
      </FullscreenContextProvider>
    </MusicContextProvider>
  );
};

export default App;
